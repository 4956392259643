@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  overflow: hidden;
}

.app {
  display: flex;
  position: relative;
}

.content {
  white-space: nowrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 769px) {
  ::-webkit-scrollbar {
    width: 1px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: rgba(155, 155, 155, 0.5);
    /* border-radius: 20px; */
    border: transparent;
  }
}
